import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import { createNextIndexGenerator } from '@/utils/numbers';
import { filterCollapsedGroups, getDataBoundaries, calculateLineMetrics } from '../../internal/utils';
import { addColorsToDataset, fillDataset } from '../../internal/LineChart/utils';
import { createSeriesData } from './utils';
export function createData(_ref) {
  let {
    results,
    selectionLabels,
    selectionLegends = [],
    seriesOptions,
    collectionOptions = [],
    globalSeriesOptions = {},
    colorPallet = [],
    chartType,
    legends = [],
    areaChartDefaultFill,
    themeStyles
  } = _ref;
  let valueAxisTemplate = '';
  let maxSeries = 0;
  const collectionBackgroundColors = [];
  const collectionBorderColors = [];
  const collectionBorderWidths = [];
  const collectionBorderDashes = [];
  const collections = results.map((collection, collectionIndex) => {
    const getNextLabelIndex = createNextIndexGenerator(); // Get the collection options

    const collectionOption = collectionOptions[collectionIndex] || {}; // Count the number of series in the collection

    const seriesCount = collection.reduce((count, selectionResultGroup) => count + selectionResultGroup.data.length, 0);
    maxSeries = Math.max(maxSeries, seriesCount);
    const result = collection.map(selectionResultGroup => {
      const {
        selectionIndex
      } = selectionResultGroup;
      const options = merge({}, globalSeriesOptions, seriesOptions[selectionIndex] || {}); // Use the first template found
      // as the template for the value axis

      if (options.template && !valueAxisTemplate) {
        valueAxisTemplate = options.template;
      }

      return {
        group: selectionResultGroup,
        options,
        selectionIndex
      };
    }) // Filter collapsed
    .filter(_ref2 => {
      let {
        group,
        options
      } = _ref2;
      return filterCollapsedGroups(group, options);
    }) // Create series data
    .map(_ref3 => {
      let {
        group,
        options,
        selectionIndex
      } = _ref3;
      return createSeriesData({
        group,
        options,
        selectionIndex,
        colorPallet,
        defaultColor: colorPallet[collectionIndex],
        themeStyles,
        selectionLabels,
        getNextLabelIndex
      });
    }); // Get the line options
    // in order: collection, global

    const lineBackgroundColor = (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineBackgroundColor) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineBackgroundColor) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineBackgroundColor) || '';
    const lineBorderColor = (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineColor) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineColor) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineColor) || colorPallet[collectionIndex] || colorPallet[0];
    const lineBorderWidth = (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineWidth) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineWidth) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineWidth) || 1;
    const lineBorderDash = (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineDash) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineDash) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineDash) || [];
    collectionBackgroundColors.push(lineBackgroundColor);
    collectionBorderColors.push(lineBorderColor);
    collectionBorderWidths.push(lineBorderWidth);
    collectionBorderDashes.push(lineBorderDash);
    return result;
  }); // Create default dataset

  const labels = Array(maxSeries).fill('');
  const datasets = [];
  collections.forEach((collection, collectionIndex) => {
    const dataset = {
      data: [],
      backgroundColor: [],
      pointBackgroundColor: [],
      pointRadius: [],
      pointStyle: [],
      borderColor: [],
      templates: [],
      titles: [],
      selections: [],
      selectionIndexes: [],
      datalabels: [],
      label: legends[collectionIndex] || selectionLegends[collectionIndex] || `Legend ${collectionIndex + 1}`,
      borderWidth: collectionBorderWidths[collectionIndex],
      borderDash: collectionBorderDashes[collectionIndex]
    };
    flatten(collection).forEach((seriesData, seriesIdx) => {
      // Use the first label found
      if (seriesData.name && !labels[seriesIdx]) {
        labels[seriesIdx] = seriesData.name;
      }

      fillDataset(seriesData, dataset);
    });
    const collectionOption = collectionOptions[collectionIndex] || {};
    addColorsToDataset({
      dataset: dataset,
      borderColor: collectionBorderColors[collectionIndex],
      backgroundColor: collectionBackgroundColors[collectionIndex] || (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.color),
      fill: chartType === 'vertical' ? 'start' : 'origin',
      areaChartDefaultFill,
      themeStyles
    });

    if (dataset.data.length > 0) {
      datasets.push(dataset);
    }
  }); // Calculate min/max

  const dataBoundaries = getDataBoundaries(datasets); // Sort datasets based on line metrics
  // Lines with lower weights are drawn first (bottom layer).
  // Lines with higher weights are drawn last (top layer).
  // This keeps higher-impact lines from hiding lower-impact ones.

  const sortedDatasets = [...datasets].sort((a, b) => {
    const metricsA = calculateLineMetrics(a.data);
    const metricsB = calculateLineMetrics(b.data); // Sort by weight

    if (metricsA.weight !== metricsB.weight) {
      return metricsA.weight - metricsB.weight;
    } // Sort by length if weights are equal


    return b.data.length - a.data.length;
  });
  return {
    legendsCount: datasets.length,
    labels: labels,
    datasets: sortedDatasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate
  };
}