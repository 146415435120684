import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import { useMemo } from 'react';
import { transparentize } from '@/utils/colors';
import { createNextIndexGenerator } from '@/utils/numbers';
import { filterCollapsedGroups, getDataBoundaries, calculateLineMetrics } from '../internal/utils';
import { createSeriesData, fillDataset } from './utils';
export default function useData(_ref) {
  let {
    results,
    selectionLabels = [],
    selectionLegends = [],
    globalSeriesOptions = {},
    seriesOptions = [],
    collectionOptions = [],
    legends = [],
    colorPallet,
    themeStyles
  } = _ref;
  const chartData = useMemo(() => {
    if (!results || !results.length) {
      return {
        labels: [],
        datasets: []
      };
    }

    let maxSeries = 0;
    const collections = results.map((collection, collectionIndex) => {
      const getNextLabelIndex = createNextIndexGenerator();
      const result = collection.map(selectionResultGroup => {
        const {
          selectionIndex
        } = selectionResultGroup;
        const options = merge({}, globalSeriesOptions, seriesOptions[selectionIndex] || {}); // Find the max number of series

        maxSeries = Math.max(maxSeries, selectionResultGroup.data.length);
        return {
          group: selectionResultGroup,
          options,
          selectionIndex
        };
      }) // Filter collapsed
      .filter(_ref2 => {
        let {
          group,
          options
        } = _ref2;
        return filterCollapsedGroups(group, options);
      }).map(_ref3 => {
        let {
          group,
          options,
          selectionIndex
        } = _ref3;
        return createSeriesData({
          group,
          options,
          selectionIndex,
          colorPallet,
          defaultColor: colorPallet[collectionIndex],
          themeStyles,
          selectionLabels,
          getNextLabelIndex
        });
      });
      return result;
    }); // Extract line styles

    const lineStyles = flatten(collections).map((_, index) => getLineStyles(collectionOptions[index], globalSeriesOptions, themeStyles, colorPallet, index)); // Create datasets

    const datasets = flatten(collections).map((collection, collectionIndex) => {
      const dataset = createInitialDataset(collectionIndex, lineStyles[collectionIndex] || {}, legends, selectionLegends);
      flatten(collection).forEach(seriesData => {
        fillDataset(seriesData, dataset);
      });
      return dataset;
    }).filter(dataset => dataset.data.length > 0); // Calculate min/max

    const dataBoundaries = getDataBoundaries(datasets); // Sort datasets based on line metrics
    // Lines with lower weights are drawn first (bottom layer).
    // Lines with higher weights are drawn last (top layer).
    // This keeps higher-impact lines from hiding lower-impact ones.

    const sortedDatasets = [...datasets].sort((a, b) => {
      const metricsA = calculateLineMetrics(a.data);
      const metricsB = calculateLineMetrics(b.data); // Sort by weight

      if (metricsA.weight !== metricsB.weight) {
        return metricsA.weight - metricsB.weight;
      } // Sort by length if weights are equal


      return b.data.length - a.data.length;
    }); // Fill in any missing labels with defaults and ignore any extras.

    const labels = selectionLabels.slice(0, maxSeries);

    if (labels.length < maxSeries) {
      for (let i = labels.length; i < maxSeries; i++) {
        labels.push(`Label ${i + 1}`);
      }
    }

    return {
      legendsCount: sortedDatasets.length,
      labels: labels,
      datasets: sortedDatasets,
      dataBoundaries: {
        min: Math.min(dataBoundaries.min, 0),
        max: dataBoundaries.max
      }
    };
  }, [results, selectionLabels, selectionLegends, seriesOptions, legends, globalSeriesOptions, collectionOptions, colorPallet, themeStyles]);
  return {
    chartData
  };
}

// Helper function to extract line styles from options
function getLineStyles(collectionOption, globalSeriesOptions, themeStyles, colorPallet, collectionIndex) {
  return {
    backgroundColor: getLineBackgroundColor(collectionOption, globalSeriesOptions, themeStyles, colorPallet, collectionIndex),
    borderColor: (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineColor) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineColor) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineColor) || colorPallet[collectionIndex] || colorPallet[0],
    borderWidth: (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineWidth) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineWidth) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineWidth) || 1,
    borderDash: (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineDash) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineDash) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineDash) || []
  };
}

function getLineBackgroundColor(collectionOption, globalSeriesOptions, themeStyles, colorPallet, collectionIndex) {
  const backgroundColor = (collectionOption === null || collectionOption === void 0 ? void 0 : collectionOption.lineBackgroundColor) || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.lineBackgroundColor) || (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.lineBackgroundColor);
  if (backgroundColor) return backgroundColor;

  if (themeStyles.areaDefaultFill === 'transparent') {
    return 'transparent';
  } // Return the fade gradient if no color is provided


  const defaultColor = colorPallet[collectionIndex] || colorPallet[0];
  return context => {
    const chart = context.chart;
    const {
      ctx,
      chartArea
    } = chart;

    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }

    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop((themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStart) ?? 1, transparentize(defaultColor, (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStartOpacity) ?? 1));
    gradient.addColorStop((themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStop) ?? 0, transparentize(defaultColor, (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStopOpacity) ?? 0));
    return gradient;
  };
}

function createInitialDataset(collectionIndex, lineStyles, legends, selectionLegends) {
  return {
    data: [],
    label: legends[collectionIndex] || selectionLegends[collectionIndex] || `Legend ${collectionIndex + 1}`,
    backgroundColor: lineStyles.backgroundColor,
    templates: [],
    titles: [],
    selections: [],
    selectionIndexes: [],
    datalabels: [],
    pointRadius: [],
    pointStyle: [],
    pointBackgroundColor: [],
    borderColor: lineStyles.borderColor,
    borderWidth: lineStyles.borderWidth,
    borderDash: lineStyles.borderDash
  };
}